let MarketIndex = function(tokenClient, serviceAddr) {
    let TokenClient = tokenClient
    let ServiceBaseAddress = serviceAddr

    /**
     * 获取商场首页信息
     * @param oranizationId
     * @param success
     * @param error
     * @constructor
     */
    this.MarketIndexData = function(oranizationId, success, error) {
        var url = ServiceBaseAddress + '/api/Merchant/Index'
        TokenClient.Get(url, true, null, null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    
}
export {MarketIndex}
